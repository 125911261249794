// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {"I2226:42823;2222:2122": {hover: true}};

const cycleOrder = ["I2226:42823;2222:2122"];

const variantClassNames = {"I2226:42823;2222:2122": "framer-v-gz9vxp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; icon?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "I2226:42823;2222:2122", icon: lmnSVWLch = "Gear", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I2226:42823;2222:2122", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ZrA0V", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-gz9vxp", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I2226:42823;2222:2122"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgba(45, 33, 53, 0.7))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-81ab93ff-d515-4aca-b63b-24f83b57a3ab, rgba(31, 23, 34, 0.5))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} transition={transition} variants={{"I2226:42823;2222:2122-hover": {"--border-color": "var(--token-9ed7888c-aeb5-48dd-aee6-b885d99628b4, rgba(218, 197, 167, 0.3)) /* {\"name\":\"border/default hover\"} */", backgroundColor: "var(--token-b4804a4b-1cf7-4023-83f4-858a0ef62630, rgba(44, 33, 51, 0.5))"}}} {...addPropertyOverrides({"I2226:42823;2222:2122-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-k5rl9u-container"} layoutDependency={layoutDependency} layoutId={"eeekdjZBa-container"} transition={transition}><Phosphor color={"var(--token-efbc6c56-80fe-4475-9b37-9684d6e92632, rgb(239, 237, 253)) /* {\"name\":\"icon/default\"} */"} height={"100%"} iconSearch={"House"} iconSelection={lmnSVWLch} id={"eeekdjZBa"} layoutId={"eeekdjZBa"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"light"} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ZrA0V [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZrA0V .framer-hbt9k7 { display: block; }", ".framer-ZrA0V .framer-gz9vxp { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 10px 10px 10px 10px; position: relative; width: min-content; }", ".framer-ZrA0V .framer-k5rl9u-container { flex: none; height: 18px; position: relative; width: 18px; }", ".framer-ZrA0V .framer-v-gz9vxp .framer-gz9vxp { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZrA0V .framer-gz9vxp { gap: 0px; } .framer-ZrA0V .framer-gz9vxp > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-ZrA0V .framer-gz9vxp > :first-child { margin-left: 0px; } .framer-ZrA0V .framer-gz9vxp > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38
 * @framerIntrinsicWidth 38
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Q80X6CjcH":{"layout":["auto","auto"]}}}
 * @framerVariables {"lmnSVWLch":"icon"}
 */
const Framergfz8p6pur: React.ComponentType<Props> = withCSS(Component, css, "framer-ZrA0V") as typeof Component;
export default Framergfz8p6pur;

Framergfz8p6pur.displayName = "Icon";

Framergfz8p6pur.defaultProps = {height: 38, width: 38};

addPropertyControls(Framergfz8p6pur, {lmnSVWLch: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Gear", hidden: undefined, title: "Icon"}} as any)

addFonts(Framergfz8p6pur, [...PhosphorFonts])